import React, { useEffect, useState } from "react";
import styles from "./ProgramContents.module.css";
import axios from "axios";
import { ProgramApi } from "../dashboard/postService";

const ProgramContents = ({ data, theme }) => {
  const [programSlug, setProgramSlug] = useState("ayushi-eps");
  const [modules, setModules] = useState([]);
  const [summary, setSummary] = useState([]);

  const [open, setOpen] = useState(() => {
    const initialOpenState = {};
    data.modules.forEach((item) => {
      initialOpenState[item.id] = data.openKeys.includes(item.id);
    });
    return initialOpenState;
  });

  const toggleItem = (item) => {
    setOpen((prevState) => ({
      ...prevState,
      [item]: !prevState[item],
    }));
  };

  useEffect(() => {
    const fetchModules = async () => {
      let response = await ProgramApi.modules(programSlug);
      if(response.success){
        setModules(response.data.modules);
       let summaryData = response.data.summary;

       summary = [
        `${summaryData['modules']} Modules`,
        `${summaryData['lessons']} Lessons`,
        `${summaryData['course_duration']} hrs of content`,
       ];

       setSummary(summary);
      }else{

      }
    };
 
    if(programSlug) fetchModules();
   }, [programSlug]);

  return (
    <div
      className={`accordion ${styles.accordion} ${
        theme.style === "light" ? styles.lightThemeAccordion : ""
      }`}
      id="accordionPanelsStayOpenExample"
    >
      <div className={styles.courseSummaryBlock}>
        <div className={styles.courseSummaryContent}>
          {summary.map((item, index) => (
            <React.Fragment key={index}>
              <p className={styles.courseSummaryText}>{item}</p>
              {index < data.summary.length - 1 && (
                <div className={styles.divider}></div>
              )}
            </React.Fragment>
          ))}
        </div>
      </div>

      {modules.map((item) => (
        <div key={item.id} className={`accordion-item ${styles.accordionItem}`}>
          <div
            className={`accordion-header ${styles.accordionHeader}`}
            id={`panelsStayOpen-heading-${item.id}`}
          >
            <button
              className={`accordion-button ${styles.accordionButton} ${
                open[item.id] ? "" : "collapsed"
              }`}
              type="button"
              onClick={() => toggleItem(item.id)}
              aria-expanded={open[item.id]}
              aria-controls={`panelsStayOpen-collapse-${item.id}`}
            >
              <div className={`flex-grow flex-col ${styles.buttonContent}`}>
                <div className={`${styles.accordionSubtitle}`}>
                  {item.subtitle}
                </div>
                <div className={`${styles.accordionTitle} mb-1`}>
                  {item.name}
                </div>
              </div>
            </button>
          </div> 
          <div
            id={`panelsStayOpen-collapse-${item.id}`}
            className={`accordion-collapse collapse ${
              open[item.id] ? "show" : ""
            } ${styles.accordionCollapse}`}
            aria-labelledby={`panelsStayOpen-heading-${item.id}`}
          >
            <div className={`accordion-body ${styles.accordionBody}`}>
              {item.lessons.map((lesson, index) => (
                <div key={index}>
                  <div
                    data-testid="table-of-contents-list-item"
                    className={`${styles.tableOfContentsListItem}`}
                  >
                    <section className={`${styles.lessonImageContainer}`}>
                      <img
                        src={lesson.thumbnail}
                        alt="lesson-cover"
                        className={`${styles.lessonImage}`}
                        loading="lazy"
                      />
                    </section>
                    <section className={`${styles.lessonDetails}`}>
                      <div className={`${styles.lessonCaption}`}>
                        <span>Session {lesson.session_counter}</span>
                      </div>
                      <div className={`${styles.lessonTitle}`}>
                        {lesson.title}
                      </div>
                      <div className={`${styles.lessonDescription}`}>
                        {lesson.subtitle}
                      </div>
                      {lesson.state === "available" ? (
                        <div className={`${styles.previewLabelContainer}`}>
                          <div className={`${styles.previewLabel}`}>
                            Watch Now
                          </div>
                        </div>
                      ) : lesson.state === "future" ? (
                        <div className={`${styles.previewLabelContainer}`}>
                          <div
                            className={`${styles.previewLabel} ${styles.warningLabel}`}
                          >
                            {lesson.preview_label}
                          </div>
                        </div>
                      ) : null}
                    </section>
                    <section className={`${styles.iconSection}`}>
                      <div className={`${styles.iconContainer}`}>
                        {lesson.state === "locked" ? (
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M9.17157 4.17157C9.92172 3.42143 10.9391 3 12 3C13.0609 3 14.0783 3.42143 14.8284 4.17157C15.5786 4.92172 16 5.93913 16 7V10H8V7C8 5.93913 8.42143 4.92172 9.17157 4.17157ZM6 10V7C6 5.4087 6.63214 3.88258 7.75736 2.75736C8.88258 1.63214 10.4087 1 12 1C13.5913 1 15.1174 1.63214 16.2426 2.75736C17.3679 3.88258 18 5.4087 18 7V10H19C20.6569 10 22 11.3431 22 13V20C22 21.6569 20.6569 23 19 23H5C3.34315 23 2 21.6569 2 20V13C2 11.3431 3.34315 10 5 10H6Z"
                              fill="currentColor"
                            />
                          </svg>
                        ) : lesson.state === "available" ? (
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z"
                              fill={
                                theme.style === "dark"
                                  ? "#ffffff"
                                  : theme.gradient1
                              }
                            />
                          </svg>
                        ) : (
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M11 6v8h7v-2h-5v-6h-2zm10.854 7.683l1.998.159c-.132.854-.351 1.676-.652 2.46l-1.8-.905c.2-.551.353-1.123.454-1.714zm-2.548 7.826l-1.413-1.443c-.486.356-1.006.668-1.555.933l.669 1.899c.821-.377 1.591-.844 2.299-1.389zm1.226-4.309c-.335.546-.719 1.057-1.149 1.528l1.404 1.433c.583-.627 1.099-1.316 1.539-2.058l-1.794-.903zm-20.532-5.2c0 6.627 5.375 12 12.004 12 1.081 0 2.124-.156 3.12-.424l-.665-1.894c-.787.2-1.607.318-2.455.318-5.516 0-10.003-4.486-10.003-10s4.487-10 10.003-10c2.235 0 4.293.744 5.959 1.989l-2.05 2.049 7.015 1.354-1.355-7.013-2.184 2.183c-2.036-1.598-4.595-2.562-7.385-2.562-6.629 0-12.004 5.373-12.004 12zm23.773-2.359h-2.076c.163.661.261 1.344.288 2.047l2.015.161c-.01-.755-.085-1.494-.227-2.208z"
                              fill={
                                theme.style === "dark"
                                  ? "#ffcc00"
                                  : theme.gradient1
                              }
                            />
                          </svg>
                        )}
                      </div>
                    </section>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ProgramContents;
