import React, { useEffect, memo, useState } from "react";
import MeetMentor from "../../components/programs/MeetMentor";
import MentorExperience from "../../components/programs/MentorExperience";


const ProgramInstructor = ({ data, theme }) => {
  return (
    <div>
      <MeetMentor data={data.superMentorData} theme={theme} />
      <MentorExperience data={data.mentoredBrands} theme={theme} />
    </div>
  );
};

export default ProgramInstructor;
