import React from "react";

import stylesHero from "./HeroComponent.module.css";

import HeadingStroke from "./HeadingStroke";
import ProgramContents from "./ProgramContents";

const ProgramCurriculum = ({ data, theme }) => {

  return (
    <div
      className={`${stylesHero["banner-small"]} ${theme.style === "light" ? stylesHero["banner-dark-theme"] : ""}`}
    >
      <div className={`px-20 ${stylesHero["container-xl-low-padding"]}`}>
        <div className={`${stylesHero["banner-heading-wrapper"]}`}>
          <h2
            className={`text-center ${stylesHero["banner-small-content-heading"]}`}
          >
            {data.pagetitle}
          </h2>
          <HeadingStroke
            className="heading-shape"
            fillColor={theme.gradient1}
          />
        </div>
        <ProgramContents data={data} theme={theme}/>
        </div>
        </div>
  );
};

export default ProgramCurriculum;