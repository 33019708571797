import {
  BookOpenIcon,
  ClockIcon,
  UserGroupIcon,
  VideoCameraIcon,
  CalendarIcon,
  GlobeAltIcon,
} from "@heroicons/react/outline"; // Adjust the import path as necessary

export const pagedata = {
  id: 1,
  slug: "ayushi-eps",
  typingText: "Score More Study Less",
  heroData: {
    title: "Exponential Power Student Program",
    typingTitle: [
      { id: 1, text: "SCORE MORE" },
      {
        id: 2,
        text: ["STUDY LESS", "MANAGE TIME", "LESS DISTRACTION", "MORE FOCUS"],
        className: "text-white-color",
      },
    ],
    infoTiles: [
      { id: 1, icon: BookOpenIcon, content: "27 Sessions" },
      { id: 2, icon: ClockIcon, content: "28.5 hours" },
      { id: 3, icon: UserGroupIcon, content: "11 Live sessions" },
    ],
    sideStats: [
      {
        iconSrc:
          "https://res.cloudinary.com/mindvalley/image/upload/v1586763030/MV-Launches/template/ico-alt-students.svg",
        iconAlt: "Students Icon",
        title: "144,470",
        subtitle: "Students Enrolled",
      },
      {
        iconSrc:
          "https://res.cloudinary.com/mindvalley/image/upload/v1586763030/MV-Launches/template/ico-alt-ribbon.svg",
        iconAlt: "Icons Of Recognition",
        title: "4.8/5",
        subtitle: "Google Reviews",
      },
      {
        iconSrc:
          "https://d14a823tufvajd.cloudfront.net/images/AqPa109B2WOTvQiCVWBW.png",
        iconAlt: "Icons Of Recognition",
        title: "86%",
        subtitle: "Completion Rate",
      },
    ],
    description:
      "<p><strong>Signature Mentorship Program:</strong> Unleash your hidden potential for exponential growth in academics and personal life&nbsp;</p> <ul> <li><strong>Change your Beliefs</strong></li> <li><strong>Master your Memory</strong></li> <li><strong>Ace Your Study Strategy</strong></li> <li><strong>Gain Warrior-Like Confidence</strong></li> </ul>",
    videoId: "949108417",
    videoUrl:
      "https://vz-dbeb695c-154.b-cdn.net/f8ba3d92-dae3-4409-b146-78f15cb939e5/playlist.m3u8",
    videoThumbnailUrl:
      "https://vz-915da578-c14.b-cdn.net/515051d6-105d-4e68-b622-9d5df4df1deb/thumbnail_4dd17cf0.jpg",
    backgroundImageUrl:
      "https://assets-global.website-files.com/5fdb2866020c200cd7fd7369/61613e3e518d5e0ab00a6319_header-background-image.jpg",
  },
  eventDetails: {
    influencerName: "Aayushi",
    influencerShortBio:
      "15000+ Students | Chartered Accountant | First Attempt | CFA L1 & CS Executive | Ex-PwC",
    influencerBannerImage:
      "https://toppernotes.s3.ap-south-1.amazonaws.com/images/kJ2q5dgmpnX17varc399.png",
    numberOfDays: 5,
    typeSessions: "Live + Recorded",
    startDate: "1st July 2024",
    registerTill: "08/07/2024 19:00:00",
    registerTillZone: "August 23, 2022 17:00:00 PDT",
    timeOfSessions: "6 to 9PM",
    joinButtonLabel: "Register now at just",
    salePrice: 9999,
    originalPrice: 19999,
    bonusesWorth: 17000,
    trainingHours: "48.5 hours",
    nextIntake: "Jul 2, 2024",
    joinTagLine: "Demo tag line",
    seatsLeft: 49,
    totalSeats: 100,
    bottomButtonText: "Reserve Now",
    eventtype: "masterclass",
    eventTimer: {
      days: 12,
      hours: 12,
      minutes: 34,
      seconds: 12,
    },
    title: [
      { id: 1, text: "Join us for the ", className: "text-black-color" },
      { id: 2, text: "Workshop" },
    ],
    eventChips: [
      {
        imgSrc:
          "https://assets-global.website-files.com/5fdb2866020c200cd7fd7369/61613e3e518d5e6aef0a6318_Vectorclock.svg",
        alt: "Clock Icon",
        icon: ClockIcon,
        heading: `5 Days`,
      },
      {
        imgSrc:
          "https://assets-global.website-files.com/5fdb2866020c200cd7fd7369/61613e3e518d5e39520a6335_Vectortv-.svg",
        alt: "Live Icon",
        icon: VideoCameraIcon,
        heading: "Live + Recorded",
      },
      {
        imgSrc:
          "https://assets-global.website-files.com/5fdb2866020c200cd7fd7369/61613e3e518d5e82bd0a6336_Vectorcalender.svg",
        alt: "Calendar Icon",
        icon: CalendarIcon,
        heading: "8th July",
      },
      {
        imgSrc:
          "https://assets-global.website-files.com/5fdb2866020c200cd7fd7369/61613e3e518d5e494a0a6337_Vectorclock%20(1).svg",
        alt: "Time Icon",
        icon: GlobeAltIcon,
        heading: "English",
      },
    ],
  },
  contents: {
    pagetitle: "The Curriculum",
    summary: ["4 Modules", "41 Lessons", "48.5 hrs of content"],
    openKeys: ["module1", "module2", "module3", "module4"],
    modules: [
      {
        id: "module1",
        title: "Learn the art of Studying",
        superscript: "Week 1 & 2",
        lessons: [
          {
            image:
              "https://toppersnotes-videos.b-cdn.net/webinars-programs/ayushi-pics/resized-images/DALL%C2%B7E%202024-07-18%2012.22.30%20-%20A%2016_9%20image%20in%20a%20blue%20theme%20featuring%20the%20title%20'Get%2C%20Set%2C%20Go!'%20and%20the%20description%20'Get%20ready%20and%20set%20your%20goals'.%20The%20design%20should%20be%20modern%20and%20e.webp.webp",
            title: "Get, Set, Go!",
            duration: "9 mins",
            id: "Session 1",
            state: "locked",
            description: "Get ready and set your goals",
          },
          {
            image:
              "https://toppersnotes-videos.b-cdn.net/webinars-programs/ayushi-pics/resized-images/DALL%C2%B7E%202024-07-18%2012.22.33%20-%20A%2016_9%20image%20in%20a%20blue%20theme%20featuring%20the%20title%20'Create%20Your%20Study%20Weapon'%20and%20the%20description%20'Create%20your%20study%20tools%20and%20materials'.%20The%20design%20sh.webp.webp",
            title: "Create Your Study Weapon",
            duration: "9 mins",
            id: "Session 2",
            state: "locked",
            description: "Create your study tools and materials",
          },
          {
            image:
              "https://toppersnotes-videos.b-cdn.net/webinars-programs/ayushi-pics/resized-images/DALL%C2%B7E%202024-07-18%2012.22.35%20-%20A%2016_9%20image%20in%20a%20blue%20theme%20featuring%20the%20title%20'Prepare%20Your%20Time-Table'%20and%20the%20description%20'Prepare%20a%20detailed%20study%20timetable'.%20The%20design%20should.webp.webp",
            title: "Prepare Your Time-Table",
            duration: "9 mins",
            id: "Session 3",
            state: "locked",
            description: "Prepare a detailed study timetable",
          },
          {
            image:
              "https://toppersnotes-videos.b-cdn.net/webinars-programs/ayushi-pics/resized-images/DALL%C2%B7E%202024-07-18%2012.22.37%20-%20A%2016_9%20image%20in%20a%20blue%20theme%20featuring%20the%20title%20'Create%20Your%20Study%20Plan'%20and%20the%20description%20'Create%20a%20comprehensive%20study%20plan'.%20The%20design%20should%20b.webp.webp",
            title: "Create Your Study Plan",
            duration: "9 mins",
            id: "Session 4",
            state: "locked",
            description: "Create a comprehensive study plan",
          },
          {
            image:
              "https://toppersnotes-videos.b-cdn.net/webinars-programs/ayushi-pics/resized-images/DALL%C2%B7E%202024-07-18%2012.22.38%20-%20A%2016_9%20image%20in%20a%20blue%20theme%20featuring%20the%20title%20'Become%20Unstoppable'%20and%20the%20description%20'Develop%20an%20unstoppable%20mindset'.%20The%20design%20should%20be%20moder.webp.webp",
            title: "Become Unstoppable",
            duration: "9 mins",
            id: "Session 5",
            state: "locked",
            description: "Develop an unstoppable mindset",
          },
          {
            image:
              "https://toppersnotes-videos.b-cdn.net/webinars-programs/ayushi-pics/resized-images/DALL%C2%B7E%202024-07-18%2012.22.39%20-%20A%2016_9%20image%20in%20a%20blue%20theme%20featuring%20the%20title%20'The%20Study%20Process'%20and%20the%20description%20'Understand%20the%20study%20process'.%20The%20design%20should%20be%20modern%20a.webp.webp",
            title: "The Study Process",
            duration: "9 mins",
            id: "Session 6",
            state: "locked",
            description: "Understand the study process",
          },
          {
            image:
              "https://toppersnotes-videos.b-cdn.net/webinars-programs/ayushi-pics/resized-images/DALL%C2%B7E%202024-07-18%2012.22.41%20-%20A%2016_9%20image%20in%20a%20blue%20theme%20featuring%20the%20title%20'Study%20Technique-1'%20and%20the%20description%20'Learn%20the%20first%20study%20technique'.%20The%20design%20should%20be%20moder.webp.webp",
            title: "Study Technique-1",
            duration: "9 mins",
            id: "Session 7",
            state: "locked",
            description: "Learn the first study technique",
          },
          {
            image:
              "https://toppersnotes-videos.b-cdn.net/webinars-programs/ayushi-pics/resized-images/DALL%C2%B7E%202024-07-18%2012.22.43%20-%20A%2016_9%20image%20in%20a%20blue%20theme%20featuring%20the%20title%20'Study%20Technique-2'%20and%20the%20description%20'Learn%20the%20second%20study%20technique'.%20The%20design%20should%20be%20mode.webp.webp",
            title: "Study Technique-2",
            duration: "9 mins",
            id: "Session 8",
            state: "locked",
            description: "Learn the second study technique",
          },
          {
            image:
              "https://toppersnotes-videos.b-cdn.net/webinars-programs/ayushi-pics/resized-images/DALL%C2%B7E%202024-07-18%2012.22.45%20-%20A%2016_9%20image%20in%20a%20blue%20theme%20featuring%20the%20title%20'Study%20Technique-3'%20and%20the%20description%20'Learn%20the%20third%20study%20technique'.%20The%20design%20should%20be%20moder.webp.webp",
            title: "Study Technique-3",
            duration: "9 mins",
            id: "Session 9",
            state: "locked",
            description: "Learn the third study technique",
          },
          {
            image:
              "https://toppersnotes-videos.b-cdn.net/webinars-programs/ayushi-pics/resized-images/DALL%C2%B7E%202024-07-18%2012.22.47%20-%20A%2016_9%20image%20in%20a%20blue%20theme%20featuring%20the%20title%20'Study%20Technique-4'%20and%20the%20description%20'Learn%20the%20fourth%20study%20technique'.%20The%20design%20should%20be%20mode.webp.webp",
            title: "Study Technique-4",
            duration: "9 mins",
            id: "Session 10",
            state: "locked",
            description: "Learn the fourth study technique",
          },
          {
            image:
              "https://toppersnotes-videos.b-cdn.net/webinars-programs/ayushi-pics/resized-images/DALL%C2%B7E%202024-07-18%2012.26.48%20-%20A%2016_9%20image%20in%20a%20blue%20theme%20featuring%20the%20title%20'Study%20Technique%205%266'%20and%20the%20description%20'Learn%20the%20fifth%20and%20sixth%20study%20techniques'.%20The%20design%20sh.webp.webp",
            title: "Study Technique 5&6",
            duration: "9 mins",
            id: "Session 11",
            state: "locked",
            description: "Learn the fifth and sixth study techniques",
          },
          {
            image:
              "https://toppersnotes-videos.b-cdn.net/webinars-programs/ayushi-pics/resized-images/DALL%C2%B7E%202024-07-18%2012.26.39%20-%20A%2016_9%20image%20in%20a%20blue%20theme%20featuring%20the%20title%20'Study%20Technique%207%268'%20and%20the%20description%20'Learn%20the%20seventh%20and%20eighth%20study%20techniques'.%20The%20design.webp.webp",
            title: "Study Technique 7&8",
            duration: "9 mins",
            id: "Session 12",
            state: "locked",
            description: "Learn the seventh and eighth study techniques",
          },
          {
            image:
              "https://toppersnotes-videos.b-cdn.net/webinars-programs/ayushi-pics/resized-images/DALL%C2%B7E%202024-07-18%2012.26.53%20-%20A%2016_9%20image%20in%20a%20blue%20theme%20featuring%20the%20title%20'Study%20Technique-9'%20and%20the%20description%20'Learn%20the%20ninth%20study%20technique'.%20The%20design%20should%20be%20moder.webp",
            title: "Study Technique-9",
            duration: "9 mins",
            id: "Session 13",
            state: "locked",
            description: "Learn the ninth study technique",
          },
          {
            image:
              "https://toppersnotes-videos.b-cdn.net/webinars-programs/ayushi-pics/resized-images/DALL%C2%B7E%202024-07-18%2012.32.05%20-%20A%2016_9%20image%20in%20a%20blue%20theme%20featuring%20the%20title%20'Educational%20Surprise'%20and%20the%20description%20'Enjoy%20an%20educational%20surprise'.%20The%20design%20should%20be%20mode%20(1).webp",
            title: "Educational Surprise",
            duration: "9 mins",
            id: "Session 14",
            state: "locked",
            description: "Enjoy an educational surprise",
          },
        ],
      },
      {
        id: "module2",
        title: "10X your Exam Preparations",
        superscript: "Week 3 & 4",
        lessons: [
          {
            image:
              "https://toppersnotes-videos.b-cdn.net/webinars-programs/ayushi-pics/resized-images/resized-images%20(1)/DALL%C2%B7E%202024-07-18%2013.10.33%20-%20A%2016_9%20image%20in%20a%20green%20theme%20featuring%20the%20title%20'Revision%20Strategy'%20and%20the%20description%20'Effective%20revision%20strategies%20for%20exams'.%20The%20design%20should.webp.webp",
            title: "Revision Strategy",
            duration: "9 mins",
            id: "Session 1",
            state: "locked",
            preview_label: "This is available now",
            description: "Effective revision strategies for exams",
          },
          {
            image:
              "https://toppersnotes-videos.b-cdn.net/webinars-programs/ayushi-pics/resized-images/resized-images%20(1)/DALL%C2%B7E%202024-07-18%2013.10.35%20-%20A%2016_9%20image%20in%20a%20green%20theme%20featuring%20the%20title%20'Masterplan%20to%20Prepare%20for%20Exams'%20and%20the%20description%20'Create%20a%20masterplan%20to%20prepare%20for%20exams'.%20Th.webp.webp",
            title: "Masterplan to Prepare for Exams",
            duration: "9 mins",
            id: "Session 2",
            state: "locked",
            description: "Create a masterplan to prepare for exams",
          },
          {
            image:
              "https://toppersnotes-videos.b-cdn.net/webinars-programs/ayushi-pics/resized-images/resized-images%20(1)/DALL%C2%B7E%202024-07-18%2013.10.38%20-%20A%2016_9%20image%20in%20a%20green%20theme%20featuring%20the%20title%20'Super%20Effective%20Tip'%20and%20the%20description%20'Learn%20a%20super%20effective%20study%20tip'.%20The%20design%20should%20be%20.webp.webp",
            title: "Super Effective Tip",
            duration: "9 mins",
            id: "Session 3",
            state: "locked",
            description: "Learn a super effective study tip",
          },
          {
            image:
              "https://toppersnotes-videos.b-cdn.net/webinars-programs/ayushi-pics/resized-images/resized-images%20(1)/DALL%C2%B7E%202024-07-18%2013.10.39%20-%20A%2016_9%20image%20in%20a%20green%20theme%20featuring%20the%20title%20'Exam%20Day%20Strategy'%20and%20the%20description%20'Develop%20a%20strategy%20for%20exam%20day'.%20The%20design%20should%20be%20mode.webp.webp",
            title: "Exam Day Strategy",
            duration: "9 mins",
            id: "Session 4",
            state: "locked",
            description: "Develop a strategy for exam day",
          },
          {
            image:
              "https://toppersnotes-videos.b-cdn.net/webinars-programs/ayushi-pics/resized-images/resized-images%20(1)/DALL%C2%B7E%202024-07-18%2013.10.40%20-%20A%2016_9%20image%20in%20a%20green%20theme%20featuring%20the%20title%20'Overcome%20the%20Fear%20of%20Failure'%20and%20the%20description%20'Overcome%20the%20fear%20of%20failure%20in%20exams'.%20The%20desi.webp.webp",
            title: "Overcome the Fear of Failure",
            duration: "9 mins",
            id: "Session 5",
            state: "locked",
            description: "Overcome the fear of failure in exams",
          },
          {
            image:
              "https://toppersnotes-videos.b-cdn.net/webinars-programs/ayushi-pics/resized-images/resized-images%20(1)/DALL%C2%B7E%202024-07-18%2013.10.42%20-%20A%2016_9%20image%20in%20a%20green%20theme%20featuring%20the%20title%20'Key%20to%20Stay%20Strong'%20and%20the%20description%20'Key%20strategies%20to%20stay%20strong'.%20The%20design%20should%20be%20moder.webp.webp",
            title: "Key to Stay Strong",
            duration: "9 mins",
            id: "Session 6",
            state: "locked",
            description: "Key strategies to stay strong",
          },
          {
            image:
              "https://toppersnotes-videos.b-cdn.net/webinars-programs/ayushi-pics/resized-images/resized-images%20(1)/DALL%C2%B7E%202024-07-18%2013.10.43%20-%20A%2016_9%20image%20in%20a%20green%20theme%20featuring%20the%20title%20'Winner's%20Attitude'%20and%20the%20description%20'Develop%20a%20winner's%20attitude'.%20The%20design%20should%20be%20modern%20a.webp",
            title: "Winner's Attitude",
            duration: "9 mins",
            id: "Session 7",
            state: "locked",
            description: "Develop a winner's attitude",
          },
          {
            image:
              "https://toppersnotes-videos.b-cdn.net/webinars-programs/ayushi-pics/resized-images/resized-images%20(1)/DALL%C2%B7E%202024-07-18%2012.50.47%20-%20A%2016_9%20image%20in%20a%20green%20theme%20featuring%20the%20title%20'Change%20Your%20Vocabulary'%20and%20the%20description%20'Change%20your%20vocabulary%20for%20better%20communication'.%20The%20.webp.webp",
            title: "Change Your Vocabulary",
            duration: "9 mins",
            id: "Session 8",
            state: "locked",
            description: "Change your vocabulary for better communication",
          },
          {
            image:
              "https://toppersnotes-videos.b-cdn.net/webinars-programs/ayushi-pics/resized-images/resized-images%20(1)/DALL%C2%B7E%202024-07-18%2012.50.45%20-%20A%2016_9%20image%20in%20a%20green%20theme%20featuring%20the%20title%20'Key%20to%20Remain%20Confident'%20and%20the%20description%20'Key%20strategies%20to%20remain%20confident'.%20The%20design%20shoul.webp.webp",
            title: "Key to Remain Confident",
            duration: "9 mins",
            id: "Session 9",
            state: "locked",
            description: "Key strategies to remain confident",
          },
          {
            image:
              "https://toppersnotes-videos.b-cdn.net/webinars-programs/ayushi-pics/resized-images/resized-images%20(1)/DALL%C2%B7E%202024-07-18%2013.09.16%20-%20A%2016_9%20image%20in%20a%20green%20theme%20featuring%20the%20title%20'Deal%20with%20Negativity'%20and%20the%20description%20'Learn%20how%20to%20deal%20with%20negativity'.%20The%20design%20should%20be.webp.webp",
            title: "Deal with Negativity",
            duration: "9 mins",
            id: "Session 10",
            state: "locked",
            description: "Learn how to deal with negativity",
          },
          {
            image:
              "https://toppersnotes-videos.b-cdn.net/webinars-programs/ayushi-pics/resized-images/resized-images%20(1)/DALL%C2%B7E%202024-07-18%2013.09.18%20-%20A%2016_9%20image%20in%20a%20green%20theme%20featuring%20the%20title%20'Maintain%20Peak%20State%20for%20Peak%20Performance'%20and%20the%20description%20'Maintain%20peak%20state%20for%20peak%20perform.webp.webp",
            title: "Maintain Peak State for Peak Performance",
            duration: "9 mins",
            id: "Session 11",
            state: "locked",
            description: "Maintain peak state for peak performance",
          },
          {
            image:
              "https://toppersnotes-videos.b-cdn.net/webinars-programs/ayushi-pics/resized-images/resized-images%20(1)/DALL%C2%B7E%202024-07-18%2013.09.19%20-%20A%2016_9%20image%20in%20a%20green%20theme%20featuring%20the%20title%20'Safeguard%20Your%20Toolbox'%20and%20the%20description%20'Safeguard%20your%20tools%20for%20success'.%20The%20design%20should%20b.webp.webp",
            title: "Safeguard Your Toolbox",
            duration: "9 mins",
            id: "Session 12",
            state: "locked",
            description: "Safeguard your tools for success",
          },
          {
            image:
              "https://toppersnotes-videos.b-cdn.net/webinars-programs/ayushi-pics/resized-images/resized-images%20(1)/DALL%C2%B7E%202024-07-18%2013.09.20%20-%20A%2016_9%20image%20in%20a%20green%20theme%20featuring%20the%20title%20'Improve%20Concentration'%20and%20the%20description%20'Techniques%20to%20improve%20concentration'.%20The%20design%20should.webp.webp",
            title: "Improve Concentration",
            duration: "9 mins",
            id: "Session 13",
            state: "locked",
            description: "Techniques to improve concentration",
          },
          {
            image:
              "https://toppersnotes-videos.b-cdn.net/webinars-programs/ayushi-pics/resized-images/resized-images%20(1)/DALL%C2%B7E%202024-07-18%2013.09.21%20-%20A%2016_9%20image%20in%20a%20green%20theme%20featuring%20the%20title%20'Closing%20Note'%20and%20the%20description%20'Final%20thoughts%20and%20closing%20remarks'.%20The%20design%20should%20be%20modern.webp.webp",
            title: "Closing Note",
            duration: "9 mins",
            id: "Session 14",
            state: "locked",
            description: "Final thoughts and closing remarks",
          },
        ],
      },
      {
        id: "module3",
        title: "LIVE Breakthrough Sessions",
        superscript: "Module 3",
        lessons: [
          {
            image:
              "https://toppersnotes-videos.b-cdn.net/webinars-programs/ayushi-pics/resized-images/resized-images%20(2)/DALL%C2%B7E%202024-07-18%2015.34.52%20-%20A%20minimalistic%2016_9%20image%20in%20a%20black%20and%20gold%20theme%20featuring%20the%20title%20'Foundations'%20and%20the%20description%20'Build%20a%20strong%20foundation%20for%20success'.%20The.webp.webp",
            title: "Foundations",
            duration: "9 mins",
            id: "Session 1",
            state: "locked",
            description: "Build a strong foundation for success",
          },
          {
            image:
              "https://toppersnotes-videos.b-cdn.net/webinars-programs/ayushi-pics/resized-images/resized-images%20(2)/DALL%C2%B7E%202024-07-18%2015.34.54%20-%20A%20minimalistic%2016_9%20image%20in%20a%20black%20and%20gold%20theme%20featuring%20the%20title%20'Study%20Plan'%20and%20the%20description%20'Create%20an%20effective%20study%20plan%20for%20your%20goal.webp.webp",
            title: "Study Plan",
            duration: "9 mins",
            id: "Session 2",
            state: "locked",
            description: "Create an effective study plan for your goals",
          },
          {
            image:
              "https://toppersnotes-videos.b-cdn.net/webinars-programs/ayushi-pics/resized-images/resized-images%20(2)/DALL%C2%B7E%202024-07-18%2015.34.55%20-%20A%20minimalistic%2016_9%20image%20in%20a%20black%20and%20gold%20theme%20featuring%20the%20title%20'Execution'%20and%20the%20description%20'Execute%20your%20study%20plan%20efficiently%20and%20effec.webp.webp",
            title: "Execution",
            duration: "9 mins",
            id: "Session 3",
            state: "locked",
            description: "Execute your study plan efficiently and effectively",
          },
          {
            image:
              "https://toppersnotes-videos.b-cdn.net/webinars-programs/ayushi-pics/resized-images/resized-images%20(2)/DALL%C2%B7E%202024-07-18%2015.35.06%20-%20A%20minimalistic%2016_9%20image%20in%20a%20black%20and%20gold%20theme%20featuring%20the%20title%20'Study%2010x%20Faster'%20and%20the%20description%20'Learn%20techniques%20to%20study%2010%20times%20fas.webp.webp",
            title: "Study 10x Faster",
            duration: "9 mins",
            id: "Session 4",
            state: "locked",
            description: "Learn techniques to study 10 times faster",
          },
          {
            image:
              "https://toppersnotes-videos.b-cdn.net/webinars-programs/ayushi-pics/resized-images/resized-images%20(2)/DALL%C2%B7E%202024-07-18%2015.35.08%20-%20A%20minimalistic%2016_9%20image%20in%20a%20black%20and%20gold%20theme%20featuring%20the%20title%20'Revision%20Strategies'%20and%20the%20description%20'Implement%20effective%20revision%20strate.webp.webp",
            title: "Revision Strategies",
            duration: "9 mins",
            id: "Session 5",
            state: "locked",
            description:
              "Implement effective revision strategies for better retention",
          },
          {
            image:
              "https://toppersnotes-videos.b-cdn.net/webinars-programs/ayushi-pics/resized-images/resized-images%20(2)/DALL%C2%B7E%202024-07-18%2015.35.16%20-%20A%20minimalistic%2016_9%20image%20in%20a%20black%20and%20gold%20theme%20featuring%20the%20title%20'Notes%20Making'%20and%20the%20description%20'Master%20the%20art%20of%20effective%20note%20making'.%20.webp.webp",
            title: "Notes Making",
            duration: "9 mins",
            id: "Session 6",
            state: "locked",
            description: "Master the art of effective note making",
          },
          {
            image:
              "https://toppersnotes-videos.b-cdn.net/webinars-programs/ayushi-pics/resized-images/resized-images%20(2)/DALL%C2%B7E%202024-07-18%2015.35.18%20-%20A%20minimalistic%2016_9%20image%20in%20a%20black%20and%20gold%20theme%20featuring%20the%20title%20'Master%20Memory'%20and%20the%20description%20'Develop%20techniques%20to%20master%20your%20memory'.webp.webp",
            title: "Master Memory",
            duration: "9 mins",
            id: "Session 7",
            state: "locked",
            description: "Develop techniques to master your memory",
          },
          {
            image:
              "https://toppersnotes-videos.b-cdn.net/webinars-programs/ayushi-pics/resized-images/resized-images%20(2)/DALL%C2%B7E%202024-07-18%2015.35.35%20-%20A%20minimalistic%2016_9%20image%20in%20a%20black%20and%20gold%20theme%20featuring%20the%20title%20'Memorize%20along%20with%20section%20number'%20and%20the%20description%20'Memorize%20content%20eff.webp.webp",
            title: "Memorize along with section number",
            duration: "9 mins",
            id: "Session 8",
            state: "locked",
            description: "Memorize content effectively with section numbers",
          },
          {
            image:
              "https://toppersnotes-videos.b-cdn.net/webinars-programs/ayushi-pics/resized-images/resized-images%20(2)/DALL%C2%B7E%202024-07-18%2015.35.38%20-%20A%20minimalistic%2016_9%20image%20in%20a%20black%20and%20gold%20theme%20featuring%20the%20title%20'Ace%20the%20Exam%20Day'%20and%20the%20description%20'Strategies%20to%20ace%20your%20exam%20day'.%20The%20.webp",
            title: "Ace the Exam Day",
            duration: "9 mins",
            id: "Session 9",
            state: "locked",
            description: "Strategies to ace your exam day",
          },
        ],
      },
      {
        id: "module4",
        title: "LIVE Breakthrough Sessions",
        superscript: "Module 3",
        lessons: [
          {
            image:
              "https://assets.mindvalley.com/api/v1/assets/0cd01f4b-7fd5-4e1f-8531-f27b0f4e2b4c.jpg",
            title: "Breaking the Brakes",
            duration: "9 mins",
            id: "Lesson 1",
            state: "locked",
            description:
              "Understand how sugar affects your health and find better substitutes.",
          },
          {
            image:
              "https://assets.mindvalley.com/api/v1/assets/03e161c7-bf7e-4acd-9c34-ec64e6025552.jpg",
            title: "The Laws of Universe",
            duration: "8 mins",
            id: "Lesson 2",
            state: "locked",
            description:
              "Learn morning routines that promote mental clarity and physical health.",
          },
        ],
      },
    ],
  },
  theme: {
    style: "light", // 'light'
    gradient1: "#3e3ef8",
    gradient2: "#673ab7",
    pageBackgroundColor: "#f8f6ff", //"#f8f6ff", // "#1a1a1a"
    accordion: "#3e3ef8",
    accordionChipFill: "#3e3ef8",
    accordionChipText: "#fff",
    accordionHeader: "#fff",
    primary: "#8217ee",
    secondary: "#e0257e",
    textColorButton: "#fff",
    imageFillColor: "#fff",
    bgcolorchip: {
      light: "#3e3ef840", // Color for light theme
      dark: "#121212", // Color for dark theme
    },
    bgcolorcontainer: {
      light: "#3e3ef840", // Color for light theme
      dark: "#1a1a1a", // Color for dark theme
    },
  },
  brands: {},
  imagetestimonials: {
    title: [
      { id: 2, text: "Love" },
      { id: 1, text: " Notes from Our", className: "text-white-color" },
      { id: 3, text: " Students" },
    ],
    content: [
      {
        image:
          "https://d14a823tufvajd.cloudfront.net/images/o4dQyN7IYOcvNRVcVSC3.webp",
      },
      {
        image:
          "https://d14a823tufvajd.cloudfront.net/images/tSfkN27iUN2whz3wfolY.webp",
      },
      {
        image:
          "https://d14a823tufvajd.cloudfront.net/images/9UIsf3k2IAAH8LsAJC2J.webp",
      },
      {
        image:
          "https://d14a823tufvajd.cloudfront.net/images/MN3yU8Nu1dd1xgaiZm8o.webp",
      },
      {
        image:
          "https://d14a823tufvajd.cloudfront.net/images/TFQfPDMmA4kejU0gAbRw.webp",
      },
      {
        image:
          "https://d14a823tufvajd.cloudfront.net/images/qw3iSejQ2jKIKjQF2h1r.webp",
      },
      {
        image:
          "https://d14a823tufvajd.cloudfront.net/images/tlVTYtVNRtQ7FrurbpFs.webp",
      },
      {
        image:
          "https://d14a823tufvajd.cloudfront.net/images/YTHQh03oix7fhZTxNM5r.webp",
      },
      {
        image:
          "https://d14a823tufvajd.cloudfront.net/images/ZIFVhmwsd4pC6xoxbPl4.webp",
      },
    ],
  },
  highlights: {
    pagetitle: "Outline of the program",
    data: [
      {
        imageUrl:
          "https://cdn.prod.website-files.com/63c698560a57ab3deb917f5a/65e968b573829fd08cbecaa0_Group%201000002747.svg",
        text: "20.5+ Hours <br />Live Sessions with Mentors",
      },
      {
        imageUrl:
          "https://cdn.prod.website-files.com/63c698560a57ab3deb917f5a/65e96a21e7bce12a3eac99a2_Group%20(1).svg",
        text: "48.5+ Hours<br /> of learning & practice Sessions",
      },
      {
        imageUrl:
          "https://cdn.prod.website-files.com/63c698560a57ab3deb917f5a/65e96a2173829fd08cbf4d86_Group%20(2).svg",
        text: "7 Hours of Break through sessions",
      },
      {
        imageUrl:
          "https://cdn.prod.website-files.com/63c698560a57ab3deb917f5a/65e96a21974dd263aca2e6fe_Group%20(3).svg",
        text: "From setting plan to 10x memory techniques",
      },
      {
        imageUrl:
          "https://cdn.prod.website-files.com/63c698560a57ab3deb917f5a/65e96a21974dd263aca2e6fe_Group%20(3).svg",
        text: "Access to a community of 1000+ leaners",
      },
    ],
  },
  learninggoals: {},
  targets: {
    title: [
      { id: 2, text: "Should attend" },
      { id: 1, text: " if you are", className: "text-white-color" },
    ],
    content: [
      {
        person_type: "Students Above 14 Years",
        purpose: `
                  <p>
                  Ideal for students preparing for national and international exams such as CA, CS, CMA, CFA, UPSC, ACCA, NEET, IIT JEE, CLAT, SAT, GRE, and GMAT. Learn to master waking up early, focusing sharply, and studying efficiently to excel in your exams.
                  </p>`,
      },
      {
        person_type: "Working Professionals",
        purpose: `
                  <p>
                  Tailored for professionals eyeing further studies or big exams. Equip yourself with tools to effectively manage your time, reduce work-study conflicts, and enhance focus for high-stakes exams like UPSC, CAT, GMAT, GRE, or professional certifications..
                  </p>`,
      },
      {
        person_type: "Housewives & Young Mothers",
        purpose: `
                  <p>
                  Designed for those balancing family duties with personal academic goals. Learn time management strategies and focus techniques that enable you to prepare efficiently for exams such as UPSC, CA, or language certifications, all while managing your home..
                  </p>`,
      },
      {
        person_type: "Teachers",
        purpose: `
                  <p>
                  For educators seeking to deepen their expertise and teaching methodologies. This workshop provides strategies to increase mental clarity, reduce professional burnout, and enhance skills, helping you and your students achieve greater academic success..
                  </p>`,
      },
      {
        person_type: "Retired Individuals",
        purpose: `
                  <p>
                  Encourage continuous intellectual engagement by adopting effective study habits tailored for lifelong learning. Gain strategies to enhance focus and productivity, foster new interests, and prepare for any form of academic or hobbyist exams with renewed enthusiasm. This approach instills in you a new zeal to manifest your unfulfilled dreams and desires.
                  </p>`,
      },
      {
        person_type: "Resuming Studies, Kickstarters",
        purpose: `
                  <p>
                  Ideal for those restarting their education after a break. This workshop equips you with strategies to rekindle your study habits, efficiently manage time, and overcome mental hurdles. Rediscover your potential and prepare effectively for your next academic or professional milestone..
                  </p>`,
      },
    ],
  },
  nottargets: {},
  numbers: {
    title: [
      { id: 2, text: "Numbers" },
      { id: 1, text: " speaking", className: "text-white-color" },
    ],
    content: [
      {
        id: 1,
        type: "title-first",
        heading: "15,100+",
        title: "Students Enrolled",
        images: [
          "https://assets-global.website-files.com/64ede0a591d8114fdaa0ae7f/65eae333ddc74095430422cb_User%20images%20(2).webp",
        ],
      },
      {
        id: 2,
        type: "image-first",
        heading: "4.8/5",
        title: "Google Ratings (580+)",
        images: [
          "https://cdn.trustpilot.net/brand-assets/4.1.0/stars/stars-5.svg",
        ],
      },
      {
        id: 5,
        type: "bigger-card",
        heading: "Free Bonuses worth ₹4999",
        title: "",
        images: [
          "https://d14a823tufvajd.cloudfront.net/images/AYqKg0OGZ3vwPp3ZrWn6.png",
        ],
      },
    ],
  },
  about: {
    title: [
      { id: 1, text: "Meet", className: "text-white-color" },
      { id: 2, text: " Aayushi" },
    ],
    bio: "<p>Aayushi Garg, a <strong>Chartered Accountant</strong> and <strong>Passionate Trainer</strong> &amp; <strong>Career Coach</strong>, spearheads a mission to revolutionize how India studies. With a focus on <strong>efficient</strong> and <strong>smart study techniques</strong>, Aayushi <strong>empowers students</strong> to ace exams <strong>without exhaustive hours of study</strong>. Her impact extends to people of <strong>all ages,&nbsp;&nbsp;</strong>students, working professionals, and housewives. Guiding them to achieve <strong>dream qualifications</strong> and <strong>degrees</strong>.</p> <p>What sets Aayushi apart is her <strong>holistic approach</strong>. Beyond academic success, she aims to cultivate a <strong>positive transformation</strong>, helping individuals become <strong>better versions</strong> of themselves for a <strong>successful</strong> and <strong>fulfilling life</strong> and <strong>career</strong>. Having <strong>influenced</strong> over <strong>25,000+</strong> through her training programs, Aayushi stands as an influential personality who left a corporate career to shape a brighter educational future for others.</p> <p>She is on a <strong>mission to help 1 lakh students</strong> to achieve their goals and transforming their lives through her training workshops in the next two years. She aims to contribute to Nation building by creating a <strong>powerful army of students </strong>with a warrior like confidence.</p>",
    image:
      "https://lh3.googleusercontent.com/m5v_0rrQvH4MkXZ9GJnvdwjlh6ckdnBDeWf2dHlQJf3drxngE1AJz7LSgixf0vDtnxTh3WqZor9v2H5HYFg5Nu2ERk8R0aJp5Q=w968",
    video: "",
  },
  benefits: {
    title: [
      { id: 2, text: "Benefits" },
      { id: 1, text: " from this workshop", className: "text-white-color" },
    ],
    points: [
      {
        src: "https://lh3.googleusercontent.com/y4mT4RlxgCK7m2yBvwc9wf_6ztQh7WKEhNVXIiQPEQUUcr15Zr9jaYRBUCLy-O1bqNEIYw-mewfYysECt7WXx_YKoylpU0qOgLg=w1064",
        value: "Free 5-Day Workshop worth Rs. 5000/-",
        description:
          "Access a 5-day masterclass worth Rs. 5000/- with lifetime 24/7 video availability.",
      },
      {
        src: "https://lh3.googleusercontent.com/2kTfc7UxiPsunWx65yAHYZ4c2dienxq_QgbqZiamoqAJxCnwg8ynVCeiBOicwf5ckWjFM7rWQZT7_t8A7I2d26t0tUGE-NjbPDk=w1064",
        value: "EPS Mastermind Session Recordings worth Rs.15000/-",
        description:
          "Get access to all past EPS mastermind sessions, offering tips and techniques.",
      },
      {
        src: "https://lh3.googleusercontent.com/PAWZokJJvvXus3X04I3CWKH1urUR04gBQXgrQ_BILFaeBalBKFL5e9gZITc4y8yOrypQ7N4ug07VExvEjH6vECickSDn8AMCTlXG=w1064",
        value: "24/7 Lifetime E-library Access worth Rs. 5000/-",
        description:
          "Study virtually with others from home, maximizing productivity and consistency.",
      },
      {
        src: "https://lh3.googleusercontent.com/ikgd8p3sEb7_Ewa0TyctrkxHGa334tMdtJTNk7wOKMMYy0UMwTBScYIINyd1N3ju-zKhl0lY-nD3Ka6IfXbuWZgYZeWZXMr_wA=w1064",
        value: "21-Day Manifestation Challenge: Priceless",
        description:
          "Attract great career opportunities and thrive with this exclusive bonus challenge.",
      },
    ],
  },
  bonuses: {},
  //   bonuses: {
  //     title: [
  //       { id: 2, text: "Free", className: "text-white-color" },
  //       { id: 3, text: " Bonuses" },
  //       { id: 4, text: " worth", className: "text-white-color" },
  //       { id: 1, text: " ₹4999" },
  //     ],
  //     pagetitle: "Unlock bonuses worth ₹17,000",
  //     content: [
  //       {
  //         title: "Video Exercises",
  //         bonusImage:
  //           "https://thethinkschool.com/wp-content/uploads/2023/10/1-1.png",
  //         initialPrice: "₹9000",
  //         finalPrice: "Free",
  //         id: 0,
  //       },
  //       {
  //         title: "Cheat sheets",
  //         bonusImage:
  //           "https://thethinkschool.com/wp-content/uploads/2023/10/2-1.png",
  //         initialPrice: "₹3000",
  //         finalPrice: "Free",
  //         id: 0,
  //       },
  //       {
  //         title: "Weekly Webinars",
  //         bonusImage:
  //           "https://thethinkschool.com/wp-content/uploads/2023/10/3-1.png",
  //         initialPrice: "₹5000",
  //         finalPrice: "Free",
  //         id: 0,
  //       },
  //       {
  //         title: "2 years Mentorship",
  //         bonusImage:
  //           "https://thethinkschool.com/wp-content/uploads/2023/10/1-1.png",
  //         initialPrice: "₹9000",
  //         finalPrice: "Free",
  //         id: 0,
  //       },
  //       {
  //         title: "Bonus 5",
  //         bonusImage:
  //           "https://thethinkschool.com/wp-content/uploads/2023/10/2-1.png",
  //         initialPrice: "₹3000",
  //         finalPrice: "Free",
  //         id: 0,
  //       },
  //       {
  //         title: "Bonus 6",
  //         bonusImage:
  //           "https://thethinkschool.com/wp-content/uploads/2023/10/3-1.png",
  //         initialPrice: "₹5000",
  //         finalPrice: "Free",
  //         id: 0,
  //       },
  //       {
  //         title: "Bonus 7",
  //         bonusImage:
  //           "https://thethinkschool.com/wp-content/uploads/2023/10/1-1.png",
  //         initialPrice: "₹9000",
  //         finalPrice: "Free",
  //         id: 0,
  //       },
  //     ],
  //   },
  superMentorData: {
    pagetitle: "Meet Super Mentor",
    mentorName: "Aayushi",
    title:
      "<strong>Chartered Accountant,</strong> CFA Level 1 &amp; CS Executive. She is <strong>Ex-PwC</strong>",
    bio: [
      "She has cleared <strong>CA in her first attempt</strong> and holds CFA Level 1 and CS Executive certifications.",
      "Beyond academics, Aayushi focuses on personal growth, her training has impacted <strong>over 25,000 people</strong>, making her a significant force in education.",
      "<strong>Formerly with PwC</strong>, she aims to help 100,000 students transform their lives through her workshops in the next two years.",
      "Award-winning coach, recognized with the prestigious <strong>Unicorn Coach Award</strong> and the <strong>Crore Club Award</strong>.&nbsp;",
      "She has delivered impactful <strong>offline sessions</strong> at esteemed institutions like <strong>NAHATA Academy</strong> in Indore and <strong>Inspire Academy</strong> in Pune. Her expertise extends to online platforms, where she has conducted sessions for <strong>National IAS Academy, CICASA, WICASA, NIRC</strong>, and other renowned organizations.",
    ],
    socialCounts: {
      instagram: "11.4K",
      youtube: "3.2K",
      linkedin: "7K",
    },
    images: {
      mainImage:
        "https://toppersnotes-videos.b-cdn.net/webinars-programs/ayushi-pics/WhatsApp%20Image%202024-07-08%20at%202.59.48%20PM.jpeg",
      sliderImages: [
        "https://toppersnotes-videos.b-cdn.net/webinars-programs/ayushi-pics/IMG_8696-ezgif.com-resize.webp",
        "https://toppersnotes-videos.b-cdn.net/webinars-programs/ayushi-pics/B612_20240718_113705_354-ezgif.com-resize.webp",
        "https://toppersnotes-videos.b-cdn.net/webinars-programs/ayushi-pics/IMG_8632-ezgif.com-resize.webp",
        "https://toppersnotes-videos.b-cdn.net/webinars-programs/ayushi-pics/IMG_5715-ezgif.com-resize.webp",
      ],
    },
  },
  mentoredBrands: {
    pagetitle: "Trained 23000+ people",
    brandsDescription: "Ayushi has trained learners from brands like",
    brands: [
      {
        src: "https://nationalias.com/wp-content/uploads/2022/12/National-IAS-Academy-Logo-Final-3-768x217-3.webp",
        alt: "National IAS Academy",
      },
      {
        src: "https://scontent.fjai10-1.fna.fbcdn.net/v/t39.30808-6/301456537_783869206068045_3546093193180009898_n.png?_nc_cat=104&ccb=1-7&_nc_sid=cc71e4&_nc_ohc=BzX3tzrI2EUQ7kNvgG_y_Bh&_nc_ht=scontent.fjai10-1.fna&oh=00_AYD56Oo0pePfvVmIp9CTrAvelpBSZyY8OzM4OG_mBuD6dA&oe=669E71CF",
        alt: "Nahata academy",
      },
      {
        src: "https://inspireacademy.info/wp-content/uploads/2022/10/logo1-1.png",
        alt: "Accenture",
      },
      {
        src: "https://seeklogo.com/images/C/ca-logo-94FF27E1C3-seeklogo.com.png",
        alt: "CA",
      },
      {
        src: "https://w7.pngwing.com/pngs/100/862/png-transparent-common-proficiency-test-institute-of-chartered-accountants-of-india-india-emblem-company-logo-thumbnail.png",
        alt: "EY",
      },
    ],
  },
  mentors: {},
  texttestimonials: {
    title: [
      { id: 3, text: "What ", className: "text-white-color" },
      { id: 2, text: "our past students" },
      { id: 1, text: " are saying", className: "text-white-color" },
    ],
    useSlider: false,
    content: [
      {
        name: "Dharshne Senbagam",
        post: "CA Final Student",
        image:
          "https://user-images.trustpilot.com/6605aa957d1fce00124e075a/73x73.png",
        feedback:
          "<div > <h2 >Worth the money spent</h2> <p >Highly informative workshop which I would recommend for every student. It was an eye opening moment for a CA inter repeater like me. I badly regret for not knowing about mam and her workshop earlier. But I feel lucky that I was able to attend her session atleast now. My confidence level has boosted a lot. Gained immense knowledge by spending just few rupees. Lovely recorded and live classes.</p> <p ><strong>Date of experience:</strong> March 28, 2024</p> </div>",
        rating: 5,
      },
      {
        name: "Gayathri Murugan",
        // post: 'BCA Student',
        image:
          "https://user-images.trustpilot.com/6619f4f4427b7a0011a69d47/73x73.png",
        feedback:
          "<div ><h2 >Life transformation</h2><p >Mam really excited and overwhelmed by the way the classes and it's valuable inputs are useful<br />100 times Worth of the money and time spent <br />I repent for knowing about this 5 days workshop very late</p><p ><strong>Date of experience:</strong> April 13, 2024</p></div>",
        rating: 5,
      },
      {
        name: "Nahush Junnarkar",
        // post: '12th Board, HR College, Mumbai',
        image:
          "https://user-images.trustpilot.com/65749c6a51045c0012c01252/73x73.png",
        feedback:
          "<div> <h2>The session was very helpful for me</h2> <p>The session was very helpful for me, especially the early riser session. I struggled a lot to wake up early but the hacks shared in the session were scientifically correct and also practical. Also the part where <strong>CA Ayushi Garg</strong> tells about science behind addiction and how to overcome mobile addiction was mind blowing.</p> <p><strong>Date of experience:</strong> December 09, 2023</p> </div>",
        rating: 5,
      },
      {
        name: "Keerthi Pradeep",
        // post: 'CA IPCC Student',
        image:
          "https://user-images.trustpilot.com/6562bedb40646e00124ae942/73x73.png",
        feedback:
          "<div > <h2 >5 days masterclass was an amazing&hellip;</h2> <p >5 days masterclass was an amazing experience for me.<br />Even the introduction was filled with positivity, these classes make me feel that i will be able to clear the exam and i'm capable for that. each day was very helpful and kept motivated to study .Eagerly waiting for day 5 live class. Thank you so much for this oppurtunity . I LOVED IT</p> <p ><strong>Date of experience:</strong> November 26, 2023</p> </div>",
        rating: 5,
      },
      {
        name: "Shruti Batra",
        // post: 'CA Final Student',
        image:
          "https://user-images.trustpilot.com/65b5395e0395ff001241aa96/73x73.png",
        feedback:
          "<div > <h2 >A Great help for those who need guidance and motivation&hellip;</h2> <p >A Great help for those who need guidance in their student journey. This Masterclass helped me in knowing all the pros and cons about my study schedule, planning etc. It has motivated me a lot. Helps in improving productivity. Thanks to our mentor.</p> <p ><strong>Date of experience:</strong> January 26, 2024</p> </div>",
        rating: 5,
      },
      {
        name: "Supriya Aravind",
        // post: 'CA Final Student',
        image:
          "https://user-images.trustpilot.com/6619653d3d419800121115d6/73x73.png",
        feedback:
          "<div > <h2 >Must attended workshop to know where&hellip;</h2> <p >Must attended workshop to know where you are going wrong in study technique. U will get to know the purpose of why towards your goal. Once I know the purpose you will be unstoppable.</p> <p ><strong>Date of experience:</strong> April 12, 2024</p> </div>",
        rating: 5,
      },
    ],
  },
  videotestimonials: {
    title: [
      { id: 3, text: "Video Diaries:  ", className: "text-white-color" },
      { id: 2, text: "Journeys to Success" },
    ],
    style: "card", // 'image' / 'card'
    content: [
      {
        thumbnail:
          "https://d14a823tufvajd.cloudfront.net/images/BwNhTHUq0KCRcBekQwTK.webp",
        videoUrl:
          "https://iframe.mediadelivery.net/embed/228263/c09806b6-22cc-4445-bf35-d88be9938ead",
        title: "This workshop has been very helpful",
        name: "Komal Jain",
        // post: '',
      },
      {
        thumbnail:
          "https://d14a823tufvajd.cloudfront.net/images/3G48w1KnPHKqXxTB5N8n.webp",
        videoUrl:
          "https://iframe.mediadelivery.net/embed/228263/afb378d1-b7c3-4a86-8809-da9633b94dff",
        title: "Aayushi ma'am teaches really well",
        name: "Madhumita Choudhary",
        // post: '',
      },
      {
        thumbnail:
          "https://d14a823tufvajd.cloudfront.net/images/MXkUmJzqkpgT3yqeuBqE.webp",
        videoUrl:
          "https://iframe.mediadelivery.net/embed/228263/2bbf7a91-8232-439d-a513-421b11546a70",
        title: "This session changed me completely!",
        name: "Nandini Patel",
        // post: '',
      },
      {
        thumbnail:
          "https://d14a823tufvajd.cloudfront.net/images/5inWpf1zJRG1F9Pjgfvt.webp",
        videoUrl:
          "https://iframe.mediadelivery.net/embed/228263/49dd4f2a-725f-4133-925d-6380c1d6a760",
        title: "This session helped me gain my lost confidence",
        name: "Palak Ghanchi",
        // post: '',
      },
      {
        thumbnail:
          "https://d14a823tufvajd.cloudfront.net/images/qCKtRz5zsiZFrNkE05m4.webp",
        videoUrl:
          "https://iframe.mediadelivery.net/embed/228263/06169fd1-ecb5-4e76-b80c-611d04ba2613",
        title: "I learned priceless lessons in this 5 day workshop",
        name: "Rajeshwari",
        // post: '',
      },
    ],
  },
  faqs: {
    title: [
      { id: 2, text: "FAQs" },
      {
        id: 1,
        text: " here’s everything you may ask...",
        className: "text-white-color",
      },
    ],
    expandedKeys: ["0", "1", "5"],
    content: [
      {
        key: "1",
        question: "Is This Program Right For Me?",
        answer:
          "<p>This program will benefit anyone who is studying and wants to clear their exams. Any student pursuing higher qualifications or preparing for any competitive exams and going to appear for the exam in <strong>14 days</strong> to within the <strong>next 2 years</strong>.</p>",
      },
      {
        key: "2",
        question: "Is this Program only for CA Students?",
        answer:
          "<p>Not at all! This program is beneficial for <strong>all the students irrespective of their field of study</strong>. We have students studying for almost all the professional exams like <strong>CA, CS, CMA, Civil Services, Medical, ACCA</strong> and many other competitive exams. We have <strong>dedicated exam specific groups</strong> to connect with your peers studying for the same course or exam.</p>",
      },
      {
        key: "3",
        question: "How Long Do I Have To Complete the Program?",
        answer:
          "<p>It will require you <strong>14-28 days</strong> to complete the program. You'll get <strong>15-20 minutes bit-sized videos</strong> which you can watch anytime during the day. Even if you missed watching the sessions on some of the days, you can always watch it later. You will get <strong>Lifetime Access</strong> to the Program if you enroll before today midnight.</p>",
      },
      {
        key: "4",
        question: "Would I get Personalized 1 on 1 Mentorship?",
        answer:
          "<p>This is a <strong>group mentorship program</strong> with personalized attention whenever needed. You will get mentored along with a group of students. We have covered almost all the study-related topics and guidance in the modules. And the contents of the programs are so powerful that there is hardly any scope for confusion or query! Still if you need any support you can always reach out to us.</p>",
      },
      {
        key: "5",
        question: "What If I Have Questions Or Need Support?",
        answer:
          "<p>Once enrolled you’ll receive information from us on how best to contact us via email for questions about the coursework, or our technical support team for any system (online access or performance) issues. You will also be able to interact with <strong>Aayushi</strong> during the <strong>LIVE Mentoring Sessions</strong> and you can even ask your exam specific questions to your peers in our <strong>WhatsApp groups</strong>.</p>",
      },
    ],
  },
};
