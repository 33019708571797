import React from "react";
import styles from "./ProgramHighlights.module.css";
import stylesHero from "./HeroComponent.module.css";
import HeadingStroke from "./HeadingStroke";

import {
    generateBannerSmallImgStyle,
  } from "../events/util/theme";

const ProgramHighlights = ({ data, theme }) => {
  return (
    <div
      className={`${stylesHero["banner-small"]} ${theme.style === "light" ? stylesHero["banner-dark-theme"] : ""}`}
    >
      <div className={`px-20 ${stylesHero["container-xl-low-padding"]}`}>
        <div className={`${stylesHero["banner-heading-wrapper"]}`}>
          <h2
            className={`text-center ${stylesHero["banner-small-content-heading"]} ${theme.style === "light" ? stylesHero["text-black-color"] : ""}`}
          >
            {data.pagetitle}
          </h2>
          <HeadingStroke
            className="heading-shape"
            fillColor={theme.gradient1}
          />
        </div>
        <div className={styles["pmc-prg-card-wrap"]}>
          {data.data.map((highlight, index) => (
            <div key={index} className={`${styles["pmc-prg-card"]} ${theme.style === "light" ? styles["card-light-theme"] : ""}`}>
              <div className={styles["pmc-prg-card-img"]}>
                <img src={highlight.imageUrl} alt="" />
              </div>
              <div>
                <div
                  className={styles["pmc-text-large"]}
                  dangerouslySetInnerHTML={{ __html: highlight.text }}
                ></div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProgramHighlights;
