import React from "react";
import styles from "./MentorExperience.module.css";
import stylesHero from "./HeroComponent.module.css";
import HeadingStroke from "./HeadingStroke";

const MentorExperience = ({ data, theme }) => {
  return (
    <div
      className={`${stylesHero["banner-small"]}`}
    //   style={{ backgroundColor: theme.style === "light" ? "white" : "black" }}
    >
      <div className={`px-20 ${stylesHero["container-xl-low-padding"]}`}>
        <div className={`${stylesHero["banner-heading-wrapper"]}`}>
          <h2
            className={`text-center ${stylesHero["banner-small-content-heading"]} ${theme.style === "light" ? stylesHero["text-black-color"] : ""}`}
          >
            {data.pagetitle}
          </h2>
          <HeadingStroke
            className="heading-shape"
            fillColor={theme.gradient1}
          />
        </div>
        <div className={`${styles.socialProof} ${theme.style === "light" ? styles.socialProofDarkTheme : ""}`}>
          {data.brands.map((logo, index) => (
            <img
              key={index}
              src={logo.src}
              alt={logo.alt}
              className={`img-fluid ${styles.socialProofLogos}`}
            />
          ))}
          <div className={`${styles.textBlock}`}>{data.brandsDescription}</div>
        </div>
      </div>
    </div>
  );
};

export default MentorExperience;
