import React, { useEffect, memo, useState } from "react";
import Head from "next/head";
import { useRouter } from "next/router";
import MeetMentor from "../../components/programs/MeetMentor";
import MentorExperience from "../../components/programs/MentorExperience";
import ProgramHighlights from "../../components/programs/ProgramHighlights";
import ProgramEnrollDates from "./ProgramEnrollDates";
import ProgramCurriculum from "./ProgramCurriculum";
import VideoTestimonials from "../events/VideoTestimonials";
import ImageTestimonials from "../events/ImageTestimonials";
import Testimonials from "../events/Testimonials";
import Faq from "../../components/events/Faqs";

const isEmptyObject = (obj) => {
  return Object.keys(obj).length === 0;
};

const ProgramReviews = ({ data, theme }) => {
  return (
    <div>
      {!isEmptyObject(data.videotestimonials) && (
        <VideoTestimonials data={data.videotestimonials} theme={theme} />
      )}
      {!isEmptyObject(data.imagetestimonials) && (
        <ImageTestimonials data={data.imagetestimonials} theme={theme} />
      )}
      {!isEmptyObject(data.texttestimonials) && (
        <Testimonials data={data.texttestimonials} theme={theme} />
      )}
    </div>
  );
};

export default ProgramReviews;
