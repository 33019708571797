import React from "react";
import { ClockIcon, CalendarIcon } from "@heroicons/react/solid";
import styles from "./ProgramEnrollDates.module.scss";
import stylesHero from "./HeroComponent.module.css";
import stylesPrograms from "./Programs.module.scss";
import {
  generateRegisterButtonStyle,
  generateTextGradientStyle,
} from "../events/util/theme";
import { navigateTocheckout } from "../../pages/webinar/ayushi";

const ProgramEnrollDates = ({ data, theme }) => {
  return (
    <div
      className={`${stylesHero["banner-small"]} ${theme.style === "light" ? styles["banner-dark-theme"] : ""}`}
      style={{ backgroundColor: theme.style === "light" ? "#e0dee7" : "#161623",
        paddingBottom: '0px'
      }}
    //   style={{ backgroundColor: theme.style === "light" ? "white" : "black" }}
    >
      <div className={`px-20 ${stylesHero["container-xl-low-padding"]}`}
      >
        <div className={`py-6 ${styles.md_py_10}`}>
          <div
            className={`w-100 mx-auto align-items-center ${styles.stats_3col}`}
          >
            <div className="d-flex w-100 align-items-start justify-content-center flex-row mb-3">
              <div className="d-flex flex-wrap align-items-center justify-content-center mb-3">
                <div className="me-2">
                  <ClockIcon className={styles.stats_row_icon} />
                </div>
              </div>
              <div>
                <div className={styles.stats_row_title}>
                  <span>{data.trainingHours}</span>
                </div>
                <div className={`pb-0 ${styles.stats_row_subtitle}`}>
                  <p>of Training</p>
                </div>
              </div>
            </div>
            <div
              className={`${styles.stats_divider_container} d-none d-md-block`}
            >
              <hr className={styles.stats_divider} />
            </div>
            <div className="d-flex w-100 align-items-start justify-content-center flex-row mb-3">
              <div className="d-flex flex-wrap align-items-center justify-content-md-center mb-3">
                <div className="me-2">
                  <CalendarIcon className={styles.stats_row_icon} />
                </div>
              </div>
              <div>
                <div className={styles.stats_row_title}>
                  <span>{data.nextIntake}</span>
                </div>
                <div className={`pb-0 ${styles.stats_row_subtitle}`}>
                  <p>Next Intake</p>
                </div>
              </div>
            </div>
            <div className={`${styles.stats_divider_container}`}>
              <hr className={styles.stats_divider} />
            </div>
            <div className={`w-100 mt-2 text-center ${styles.button_programs}`}>
              <a
                href="#"
                className={`${stylesPrograms["button_programs"]} ${stylesPrograms["glare-btn"]}`}
                style={generateRegisterButtonStyle(
                  theme.gradient1,
                  theme.gradient2,
                  theme.textColorButton
                )}
              >
                <div>
                  {data.joinButtonLabel} ₹{data.salePrice}
                  <span className="text-decoration-line-through">
                    {" "}
                    ₹{data.originalPrice}
                  </span>
                </div>
                <div className={stylesPrograms.glare}></div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgramEnrollDates;
